.section-contacto {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.section-contacto-about {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.container-texto-about {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 719px;
  max-width: 715px;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-right: 34px;
}

.container-foto-about {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;

  height: 400px;
  width: 50%;
  margin-top: 75px;
}
.img-contacto-about {
  width: 50%;
  height: 430px;
  object-fit: cover;
  border-radius: 0px 500px 500px 0px;
  object-position: 80% 10%;
  position: absolute;
  left: 0;
  animation: slideIn 1s ease forwards;
  position: fixed;
}

/* .container-foto {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  min-width: 509px;
  min-height: 493px;
  max-width: 273px;
} */

/* .img-contacto {
  width: 100%;
  height: 100%;
  object-fit: cover;
 border-radius: 0px 131px 131px 0px;
  filter: grayscale(100%);
  opacity: 0.7;
} */

.titulo-contacto-about {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 200;
}
.titulo-proyecto-about {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 200;
}
.titulo-proyecto-charge {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 300;
}

.parrafo-contacto-about {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 300;
  margin-top: 0px;
}
hr {
  opacity: 0.3;
}

@media only screen and (max-width: 576px) {
  .container-foto-about {
    min-width: 353px;
    min-height: 145px;
  }
  .section-contacto-contacto {
    flex-direction: column;
  }
  .titulo-contacto-about {
    display: none;
  }
  .container-texto-about {
    flex-direction: row;
    gap: 20px;
    margin-left: 15px;
    margin-top: 10px;
    min-width: 303px !important;
  }
  .telefono-container {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1633px) {
  .img-contacto-about {
    width: 35%;
    height: 550px;
    object-fit: cover;
    border-radius: 0px 500px 500px 0px;
    object-position: 50% 10%;
    position: absolute;
    left: 0;
    position: fixed;
  }
  .container-texto-about {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* min-width: 45%;
    max-width: 45%; */
    align-items: flex-start;
    flex-wrap: wrap;
    padding-right: 34px;
  }
}
@media only screen and (max-width: 1314px) {
  .img-contacto-about {
    width: 45%;
    height: 550px;
    object-fit: cover;
    border-radius: 0px 500px 500px 0px;
    object-position: 60% 10%;
    position: absolute;
    left: 0;
  }
  .container-texto-about {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 45%;
    max-width: 45%;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-right: 34px;
  }
}

@media only screen and (max-width: 990px) {
  .img-contacto-about {
    width: 95%;
    height: 330px;
    object-fit: cover;
    border-radius: 0px 500px 500px 0px;
    object-position: 50% 10%;
    position: absolute;
    left: 0;
  }
  .container-texto-about {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 45%;
    max-width: 45%;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-right: 34px;
  }
  .section-contacto-about {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    /* margin-left: 20px; */
  }
  .container-foto-about {
    margin-top: 25px;
    height: 330px;
  }
  .container-texto-about {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 90%;
    max-width: 90%;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-right: 34px;
    width: fit-content;
    /* flex: 1; */
    /* width: auto; */
    margin-left: 20px;
    margin-right: 20px;
  }
}
.año-desplegable {
  width: 100%;
  margin-bottom: 1rem;
}

.year-title {
  cursor: pointer;
  color: #9b9b9b;
  font-weight: 300;
  display: flex;
  justify-content: space-between; /* Separa el año y la flecha */
  align-items: center;
  transition: color 0.3s ease;
  padding-right: 1rem; /* Espacio adicional al final */
  position: relative;
}

/* Ocultar el contenido inicialmente */
.content {
  /* max-height: 0;
  overflow: hidden; */
  transition: max-height 0.5s ease;
}

/* Mostrar el contenido al pasar el ratón sobre el año */
.año-desplegable:hover .content {
  max-height: 500px; /* Ajusta esto según la cantidad de contenido */
}

/* Agrega un pequeño estilo al título cuando se expande */
.año-desplegable:hover .year-title {
  color: #9c9c9c; /* Cambia el color del título al pasar el ratón */
}
hr {
  margin-top: 0px;
  margin-bottom: 0px;
  border: 0;
  border-top: 1px solid #9c9c9c;
  width: 100%;
}
.year-title::after {
  content: "";
  font-size: 1rem;
  color: #797979;
  transition: transform 0.3s ease;
}
.año-desplegable:hover .year-title::after {
  transform: rotate(180deg);
}
.category-professional {
  border: 1px solid #e2dfdf;
  padding: 15px;
  border-radius: 30px;
  margin-bottom: 30px;
  /* opacity: 0.5; */
  width: 95%;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0.5;
}

.scroll-to-top:hover {
  opacity: 1;
  transform: scale(1.1);
  cursor: pointer;
}

.cover_colaPez {
  width: 100%;
    height: 100vh;
    object-fit: scale-down;
    margin-top: 10px;
    background-color: black;
}

.lazy-load-image-background {
  display: flex !important; /* Sobrescribe el display inline-block */
}

.container {
display: flex;
    width: 100%;
    position: relative;
    /* margin-top: 67px; */
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    gap: 30px;
}


.grid-container {
    /* display: grid; */
    /* grid-template-columns: repeat(1, 159px); */

    gap: 20px;
    margin: 0;
    /* top: 970px; */
    /* left: 45%; */
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex-direction: column;
}
.lazy-load-image-background.blur.lazy-load-image-loaded > img {
    opacity: 1;
    transition: opacity .3s, transform 0.5s ease, filter 0.5s ease !important; /* Agrega todas las transiciones aquí */
}

.name-ficha{
  margin-bottom: 10px;
}
.container-ficha-centrada{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
      margin-bottom: 30px;
 
}
.container-ficha-centrada span{
font-weight: bold;
}

.grid-item {
width: 157px;
    height: 157px;
    background-color: #000000;
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 80px;
 overflow: hidden; /* Para ocultar el desbordamiento */
    position: relative; /* Para el posicionamiento de la imagen */
    transition: background-color 0.3s ease; 
    cursor: pointer;
}

.grid-item img {
    max-height: 120%; /* Asegúrate de que la imagen no exceda el tamaño del contenedor */
    object-fit: cover; /* Mantiene la proporción de la imagen y llena el contenedor */
    position: absolute; /* Posiciona la imagen de manera absoluta */
    top: 50%; /* Centra la imagen verticalmente */
    left: 50%; /* Centra la imagen horizontalmente */
    transform: translate(-50%, -50%) scale(1); /* Centrando y escalando */
  
  
}

.grid-item:hover img {
  transform: translate(-50%, -55%) scale(1.05); 
    filter: grayscale(100%); 
}




.imagen-ampliada {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.imagen-ampliada.visible {
  display: flex; /* Cambia display a flex cuando la clase visible está presente */
}

.imagen-ampliada img {
  max-width: 90%;
  max-height: 90%;
  cursor: pointer;
}

.text-ficha {
  padding: 5px;
}
.text-ficha2 {
  padding: 5px;
  margin-left: -150px;
  color: #4b4848;
  width: 127px;
  text-align: end;
  font-weight: 300;
}

.imagen-ampliada button {
  background: none;
  border: none;
  color: white;
  font-size: 34px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px;
  transition: opacity 0.3s ease;
  opacity: 0.5;
}
.imagen-ampliada button:hover {
  opacity: 0.8;
}

.button-izquierda {
  left: 20px;
}

.button-derecha {
  right: 20px;
}

.button-izquierda-cover,
.button-derecha-cover {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  font-size: 34px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.button-izquierda-cover {
  left: 20px;
}

.button-derecha-cover {
  right: 20px;
}

.button-izquierda-cover:hover,
.button-derecha-cover:hover {
  opacity: 1;
}

.indicadores {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.indicadores span {
  width: 10px;
  height: 10px;
  background-color: #bbb;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.indicadores span.activo {
  background-color: #717171;
}

@media only screen and (max-width: 900px) {
  .caja-container {
    display: none;
  }
  .grid-container {
       /* display: grid; */
    /* grid-template-columns: repeat(1, 159px); */
      gap: 3px;
    margin: 0;
    /* top: 970px; */
    /* left: 45%; */
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex-direction: column;
  }

}
@media only screen and (max-width: 720px) {
  .container {
    flex-direction: column-reverse;
  }
  .grid-container {
     gap: 3px;
    margin: 0;
    /* top: 970px; */
    /* left: 45%; */
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .button-izquierda-cover{
    top: 35%;

  }
  .button-derecha-cover{
    top: 35%;

  }
    .container-ficha-centrada{
      font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
  }
}
@media only screen and (max-width: 576px) {
 
  .grid-container {
    gap: 3px;
    margin: 0;
    /* top: 970px; */
    /* left: 45%; */
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex-direction: column;
  }
  /* .container {
    margin-top: 130px;
  } */
}

@media only screen and (max-width: 576px) {
  .cover_colaPez {
    height: 50vh;
  }

  .container-ficha-centrada{
      font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  }
}





.section-contacto-contacto {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  position: relative;
  /* margin-top: 170px; */
}

.container-texto {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 719px;
  max-width: 715px;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-right: 60px;
  margin-left: 60px;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.icon-avion {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  filter: invert(50%);
}

.text-button {
  font-weight: 500;
  opacity: 0.5;
}
.button-correo {
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  color: #000;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: color 0.4s;
  opacity: 0.7;
  border: none;
}

.telefono-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  gap: 7px;
}
.tl-span {
  width: 25px;
  opacity: 0.5;
}
.num-span {
  font-size: 1.1rem;
  font-weight: 500;
  opacity: 0.5;
  user-select: none; /* Para la mayoría de los navegadores modernos */
  -moz-user-select: none; /* Para Firefox */
  -webkit-user-select: none; /* Para Chrome y Safari */
  -ms-user-select: none; /* Para Internet Explorer y Edge */
}

.container-foto-contacto {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;

  height: 400px;
  width: 50%;
  margin-top: 75px;
}

.img-contacto-contacto {
  width: 50%;
  height: 400px;
  object-fit: cover;
  border-radius: 0px 500px 500px 0px;
  object-position: 80% 10%;
  position: absolute;
  left: 0;
  animation: slideIn 1s ease forwards; /* Aplica la animación */
  /* La duración es de 1 segundo, pero puedes ajustarla según lo desees */
}
.titulo-contacto {
  font-size: 1.6rem;
  margin-bottom: 1rem;
  font-weight: 200;
}

.parrafo-contacto {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 300;
  text-align: start;
  margin-left: 30px;
}

.container-texto-contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 719px;
  max-width: 715px;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-right: 34px;
}

@media only screen and (max-width: 1562px) {
  .container-texto {
    margin-right: 0px;
    margin-left: 0px;
  }
  .container-texto-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: auto;
    max-width: auto;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-right: 34px;
    margin-left: 30px;
  }

  .section-contacto-contacto {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    position: relative;
    /* margin-top: 120px; */
  }
  .img-contacto-contacto {
    width: 60%;
  }
}

@media only screen and (max-width: 1204px) {
  .img-contacto-contacto {
    width: 90%;
    height: 50%;
    object-position: 80% 20%;
  }
  .section-contacto-contacto {
    flex-direction: column;
  }
  .titulo-contacto {
    display: none;
  }
  .container-foto-contacto {
    height: 220px;
  }
  .container-foto-contacto {
    /* margin-top: 35px; */
  }

  .section-contacto-contacto {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    position: relative;
    /* margin-top: 120px; */
  }
}

@keyframes slideIn {
  from {
    transform: translateX(
      -100%
    ); /* Comienza desde la izquierda fuera de la pantalla */
    opacity: 0; /* Comienza invisible */
  }
  to {
    transform: translateX(0); /* Termina en su posición original */
    opacity: 1; /* Termina visible */
  }
}

@media only screen and (max-width: 525px) {
  .section-contacto-contacto {
    /* margin-top: 70px; */
  }
}

.hidden-seo-text {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); /* Hace el elemento invisible en pantalla */
  white-space: nowrap;
}

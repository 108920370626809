

/* Footer general */
.footer {
  text-align: center;
  font-size: 0.9rem;
  color:white;
}

/* Estilos específicos del contenido del footer */
.footer-content {
 display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: space-between;
    margin-top: 100px;
    opacity: 0.8;
}

.footer a {
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.header-display {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 35px;
}
.article-proyectos-display {
  margin-bottom: 20px;
}

.header-display p {
  font-size: 26px;
  font-weight: 300;
}
.article-proyectos-display {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.img-proyectos {
  width: 695px;
  height: 184px;
  flex-shrink: 0;
  border-radius: 100px;
  object-fit: cover;
  filter: grayscale(100%);
  color: transparent;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  animation: fadeIn 0.5s forwards;
  transition: transform 0.5s ease-in-out, filter 0.5s ease-in-out;
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

.img-proyectos.lazy-load-image-loaded {
  opacity: 1;
}
.img-proyectos.loaded {
  opacity: 1; /* Al cargar la imagen, se aplicará opacidad 1 */
}

.img-top {
  object-position: center 30%;
}
.article-proyectos-display .texto-proyectos-name .entrelineas span,
.article-proyectos-display .texto-proyectos-name div span,
.article-proyectos-display .texto-proyectos-name p {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  text-align: center;
}

.article-proyectos-display:hover .texto-proyectos-name .entrelineas span,
.article-proyectos-display:hover .texto-proyectos-name div span,
.article-proyectos-display:hover .texto-proyectos-name p {
  opacity: 1;
}

.img-proyectos:hover {
  filter: grayscale(0%);
  color: white;
  transform: scale(1.02); /* Imagen a color en el hover */
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
}

.texto-proyectos-name {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.texto-proyectos-name p {
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 300;
}
.entrelineas {
  gap: -10px;
  text-align: center;
}

.container-img-proyectos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 695px;
}

@media only screen and (max-width: 1200px) {
  .article-proyectos-display {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    align-items: flex-end;
  }

  .texto-proyectos-name {
    width: auto;

    align-items: flex-end;
    gap: 0px;
  }

  .article-proyectos-display .texto-proyectos-name .entrelineas span,
  .article-proyectos-display .texto-proyectos-name div span,
  .article-proyectos-display .texto-proyectos-name p {
    opacity: 1;
  }

  .responsive-align-project-name {
    display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        align-items: center;
  }
  .container-img-proyectos {
    gap: 0px;
  }

  .texto-proyectos-name p {
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
    /* font-weight: 400; */
  }
  .texto-proyectos-name span {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 300;
  }
  .entrelineas {
    display:none;
  }
}

@media only screen and (max-width: 992px) {
  .img-proyectos {
    width: 495px;
    height: 134px;
  }

  .header-display {
    margin-top: 10px;
  }
  .container-img-proyectos {
    max-width: 495px;
  }
  .text-navbar {
    font-size: 80px;
    padding-left: 50px;
  }
}

@media only screen and (max-width: 720px) {
  .img-proyectos {
    width: 495px;
    height: 134px;
  }

  .header-display {
    margin-top: 10px;
  }
  .container-img-proyectos {
    max-width: 495px;
  }
  .text-navbar {
    font-size: 50px;
  }
  .img-proyectos {
    border-radius: 100px;
  }
}

@media only screen and (max-width: 510px) {
  .img-proyectos {
          width: 365px;
        height: 120px;
  }

  .texto-proyectos-name p {
    font-size: 16px;
    opacity: 0.5;
  }
  .texto-proyectos-name span {
    font-size: 14px;
    opacity: 0.3;
  }
  .header-display {
    margin-top: 10px;
  }
 
  .texto-proyectos-name {
    gap: 0px;
  }
  .text-navbar {
    font-size: 40px;
  }
  .img-proyectos {
    border-radius: 100px;
  }
  .container-img-proyectos{
    width: 100%;
  }
}

@media only screen and (max-width: 420px) {
 
.text-navbar {
        font-size: 30px;
    }
        #menuToggle {
        top: 22px;
        left: 18px;
    }

 
}
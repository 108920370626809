.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(29, 29, 29);
}

/* button {
  background-color: #ffffff;
  border: none;
  color: rgb(29, 29, 29);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
} */

a {
  text-decoration: none;
  color: rgb(29, 29, 29);
}
h1 {
  font-weight: 200 !important;
}

body{
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  
}
html {
  scroll-behavior: smooth;

}







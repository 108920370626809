#menuToggle {
  display: block;
  position: relative;
  left: 34px;
  top: 19px;
  position: fixed;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;

  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: tomato;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
  display: block;
  width: 26px;
  height: 2.5px;
  margin-bottom: 5px;
  position: relative;
  opacity: 0.6;
  background: #4d4b4b;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}
.menu-display.scrolled h1 {
  color: #111111;
  opacity: 0.5;
  font-size: 50px;
  position: relative;
  right: 0;
  display: none;
}
.menu-display.scrolled p {
  color: #fff;
  opacity: 0.8;
}
.menuToggle.scrolled span {
  color: #fff;
  opacity: 0.8;
}
.text-navbar {
  /* Estilos iniciales */
  opacity: 0;
  transition: opacity 0.5s ease;
}

.text-navbar.visible {
  opacity: 1;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(48deg) translate(-1px, -1px);
  background: #000000;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
  position: absolute;
  width: 300px;
  height: 100vh;
  margin: -91px 0 0 -55px;
  padding: 52px;
  padding-top: 330px;
  padding-bottom: 13px;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-120%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.8)
  );
  border-radius: 15px;
  text-align: center;
}

#menu li {
  padding: 3px 0;
  font-size: 22px;
  margin: 10px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul {
  transform: none;
}

.menu-display {
  text-align: right;
  margin-right: 50px;
  top: 10px;
  z-index: 1000;
  width: 100%;
  top: 0;
}
.menu-display_special-class {
  text-align: right;
  margin-right: 50px;
  top: 10px;
  z-index: 1000;
  width: 100%;
  top: 0;
}

.div-align-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.h1 {
  font-weight: 200 !important;
  margin: 0 !important;
  font-size: 100px;
}
.nombre {
  margin-right: 60px;
  font-family: "Lato", sans-serif;
}

.nombre p {
  font-size: 25px;
  font-weight: 300;
  margin: 0 !important;
  margin-left: 20px;
  margin-top: -15px !important;
  letter-spacing: 7px;
}
.nombre_special-class {
  margin-right: 60px;
  font-family: "Lato", sans-serif;
}

.nombre_special-class p {
  font-size: 25px;
  font-weight: 300;
  margin: 0 !important;
  margin-left: 20px;
  margin-top: -15px !important;
  letter-spacing: 7px;
}

.nombre_special-class h1 {
  font-weight: 200 !important;
  margin: 0 !important;
  font-size: 100px;
}

@media only screen and (max-width: 1614px) {
  .container-nav_special-class {
    display: flex;
    /* flex-direction: row; */
    /* width: 100%; */
    /* flex-wrap: wrap; */
    align-content: flex-end;
    justify-content: flex-end;
  }

  .nombre_special-class {
    width: 45%;
    display: flex;
    margin-right: 45px;
  }

  .nombre_special-class h1 {
    font-size: 95px;
  }
}
@media only screen and (max-width: 1482px) {
  .nombre_special-class h1 {
    font-size: 85px;
  }
}
@media only screen and (max-width: 1327px) {
  .nombre_special-class h1 {
    font-size: 70px;
  }
}
@media only screen and (max-width: 1093px) {
  .nombre_special-class h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 990px) {
  .menu-display_special-class {
    background-color: white;
    position: fixed;
  }
  .nombre_special-class {
    margin-right: 60px;
    font-family: "Lato", sans-serif;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .nombre_special-class p {
    font-size: 25px;
    font-weight: 300;
    margin: 0 !important;
    margin-left: 20px;
    margin-top: -15px !important;
    letter-spacing: 7px;
  }

  .nombre_special-class h1 {
    font-weight: 200 !important;
    margin: 0 !important;
    font-size: 80px;
  }
  .section-contacto-about {
    margin-top: 90px;
  }
}

@media only screen and (max-width: 576px) {
  h1 {
    font-size: 33px;
    /* text-align: end; */
    /* width: fit-content; */
  }
  .nombre_special-class h1 {
    font-size: 33px;
  }
  .nombre_special-class p {
    margin-top: 0px !important;
  }

  .nombre p {
    margin-top: 0px !important;
  }
  /* .container-nav {
    margin-top: 9px;
  } */
  .nombre {
    margin-right: 40px;
  }
  .nombre_special-class {
    margin-right: 40px;
  }
  #menuToggle span {
    height: 2.5px;
  }
  #menuToggle {
    top: 22px;
    left: 12px;
  }
  .section-contacto-about {
    margin-top: 60px;
  }
}

/* .section-contacto-about {
  margin-top: 120px;
} */

.menu-display {
  transition: opacity 0.3s ease; /* Transición suave de opacidad */
}

.nombre {
  opacity: 1; /* Opacidad inicial */
  transition: opacity 0.3s ease; /* Transición suave de opacidad */
}
.nombre_special-class {
  opacity: 1; /* Opacidad inicial */
  transition: opacity 0.3s ease; /* Transición suave de opacidad */
}

.nombre.hidden {
  opacity: 0;
}

.nombre_special-class.hidden {
  opacity: 0;
}
